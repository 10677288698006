<template>
  <section class="monitoring-list-area">
    <div class="vx-row">
      <div class="vx-col w-full md:w-2/3">
        <!-- @filter="filter" -->
        <vx-data-list
          ref="vxDataList"
          pagination
          :data="list"
          :columns="columns"
          @selected="goToMonitoring"
          :config="{
            disablePager: true,
            selectable: true
          }"
        />
      </div>
      <div class="vx-col w-full md:w-1/3">
        <vx-card class="mb-5">
          <div class="vx-row">
            <div class="vx-col w-full  mb-5">
              <vx-field
                type="datetime"
                class="w-full mt-2"
                placeholder="Date range"
                v-model="form.datecaptured"
                :config="{
                  clearable: true,
                  dateFormat: 'd-m-Y',
                  enableTime: false,
                  mode: 'range'
                }"
              />
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </section>
</template>

<script>
// import { mapState } from 'vuex';
import PageStatusCheckThumbnail from '@/views/summary/components/PageStatusCheckThumbnail';

// import Paging from "@/entity/Paging";
export default {
  data() {
    return {
      form: {},
      columns: [
        {
          label: "Check",
          field: 'title',
          classes: "text-xs truncate max-w-48",
          component: PageStatusCheckThumbnail
        },
        {
          label: "Status",
          icon: (data) => data.online ? 'CheckCircleIcon' : 'XCircleIcon',
          color: (data) => data.online ? 'text-success' : 'text-danger',
          renderer: 'VxDataRendererIcon'
        },
        {
          label: "Availability",
          renderer: (data) => {
            try {
              return `${data.availability}%`;
            } catch (error) {
              return 'N/a'
            }
          }
        },
        {
          label: "Downtime",
          renderer: (data) => {
            try {
              return `${data.downtime.mins} mins (${data.downtime.percentage})%`;
            } catch (error) {
              return 'N/a'
            }
          }
        },
        {
          label: "Apdex",
          field: 'apdex'
        },
        {
          label: "Avg response time",
          renderer: (data) => {
            try {
              return `${data.responseTime} ms`;
            } catch (error) {
              return 'N/a'
            }
          }
        }
      ],
      list: [
        {
          title: "Test Title",
          subtitle: 'Latest Tested 5 mins ago',
          host: {
            domain: "stuff.co.nz",
            favIconPath: "",
            id: 5,
            host: "www",
            port: 443
          },
          online: true,
          availability: 99.68,
          downtime: {
            mins: 30,
            percentage: 0.05
          },
          apdex: 0.93,
          responseTime: 200
        }
      ]
    };
  },
  computed: {
    // ...mapState('templates', [ 'list' ])
  },
  methods: {
    // async fetch({ paging } = {}) {
      // await this.$store.dispatch('templates/list', {
      //   // params: params || this.filters ,
      //   paging: paging || new Paging()
      // })
    // },
    // filter(paging) {
      // this.fetch({ paging });
    // },
    // deleteItem(tr) {
    //   this.$store.dispatch("templates/delete", tr.id);
    // },
    goToMonitoring() {
      this.$router.push(`/monitoring?host=xxxx`);
    }
  },
  async created() {
    // await this.fetch()
  },
  mounted() {}
};
</script>