var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"monitoring-list-area"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full md:w-2/3"},[_c('vx-data-list',{ref:"vxDataList",attrs:{"pagination":"","data":_vm.list,"columns":_vm.columns,"config":{
          disablePager: true,
          selectable: true
        }},on:{"selected":_vm.goToMonitoring}})],1),_c('div',{staticClass:"vx-col w-full md:w-1/3"},[_c('vx-card',{staticClass:"mb-5"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full  mb-5"},[_c('vx-field',{staticClass:"w-full mt-2",attrs:{"type":"datetime","placeholder":"Date range","config":{
                clearable: true,
                dateFormat: 'd-m-Y',
                enableTime: false,
                mode: 'range'
              }},model:{value:(_vm.form.datecaptured),callback:function ($$v) {_vm.$set(_vm.form, "datecaptured", $$v)},expression:"form.datecaptured"}})],1)])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }