<template>
  <div class="summary-check-block p-3">
    <p class="font-semibold">Title goes here</p>
    <p class="text-xs text-success">https://hosturlgoeshere.com</p>
    <p class="text-xs">Latest Tested 5 mins ago</p>
  </div>
</template>

<script>
export default {
  props: ['data', 'config'],
  mounted() {
  }
}
</script>